// Full hero banner
// - - - - - - - - - - - - -

.full-hero-banner {
  position: relative;
  margin: 0;

  img {
    display: block;
    width: 100%;
  }

  .ctn-main {
    position: relative;
    width: auto;
    padding: 0 !important;
  }

  .img-ctn{
    position: relative;
    height: 612px;

    @include breakpointMin(426px) {
      height: auto;
    }

    @include breakpointMax($smaller-devices) {
      &:before,
      &:after{
        display: none;
      }
    }
  }

  .video-banner-img{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    img#videoBannerImage{
      display: none;
    }

    img#videoBannerImageMobile{
      display: block;
      width: 100%;
      max-width: inherit;
      height: 100%;
      object-fit: cover;
    }

    @include breakpointMin(426px) {
      img#videoBannerImageMobile {
        display: none;
      }
      img#videoBannerImage {
        display: block;
        width: 100%;
        max-width: inherit;
        height: 100%;
        object-fit: cover;
      }
    }

    // h2 {
    //   position: absolute;
    //   top: 35%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: 3;
    //   color: $color-bg-light;
    //   @include font-size(4);
    //   font-family: 'Maxx-Bold';
    //   word-spacing: -4px;
    //   text-wrap: nowrap;

    //   @include breakpointMin(426px) {
    //     top: 35%;
    //     left: 50%;
    //   }

    //   @include breakpointMin($mobile-medium) {
    //     top: 35%;
    //     left: 50%;
    //   }

    //   @include breakpointMin(1070px) {
    //     top: 25%;
    //     left: 75%;
    //     @include font-size(5.3);
    //   }
    //   @include breakpointMin(1430px) {
    //     top: 30%;
    //     left: 70%;
    //   }

    //   @include breakpointMin(1770px) {
    //     top: 35%;
    //     left: 65%;
    //   }

    //   @include breakpointMin(2370px) {
    //     top: 40%;
    //     left: 62%;
    //   }



    // }

  }

  .hero-banner__copy-bg {
    background-color: $phase2-darkred;
    padding: 10px;
  }

  // .hero-banner__copy {

  //   &.-bottom-box{

  //     .button{
  //       display: block;
  //       align-self: center;
  //       width: 121px;
  //       padding: 15px 10px;
  //       text-align: center;
  //     }
  //   }
  // }
}
