.site-footer{
  background-color: $color-main;
  padding-top: 41px;
  padding-bottom: 39px;
  color: $white;
  @include font-size(1.2);
  line-height: 28px;

  @include breakpointMax($mobile-medium) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .ctn-main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 13px;

    @include breakpointMin(1039px) {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}

.site-footer__social{

  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    border: 2px solid $white;
    border-radius: 50%;
    padding-top: 1px;
    //more align like mocks...
    padding-left: 1px;
    color: $white;

    @include breakpointMax($mobile-medium) {
      margin: 0 3px;
    }

    &:hover{
      //background-color: rgba($white, 0.2);
      background-color: $black;
    }
  }

  a[name="tiktok"] img{
    max-width: 24px;
  }

  img{
    display: block;
  }
}

.site-footer__nav,
.site-footer__legal{
  margin-top: 13px;
  line-height: 35px;
  @include font-size(1.6);

  @include breakpointMax($mobile-medium) {
    @include font-size(1.1);
  }
}

.site-footer__nav{
  align-self: flex-end;
  width: 70%;

  @include breakpointMax($mobile-medium) {
    width: 100%;
    // margin-bottom: 18px;
  }

  li{
    display: inline-block;
  }

  a{
    padding: 0 8px;
    color: $white;
    text-decoration: underline;
    transition: color 0.5s;

    &:hover{
      color: $black;
    }
  }
}

.site-footer__legal{
  text-align: right;
  max-width: 300px;
  line-height: 1em;

  @include breakpointMax(1259px) {
    text-align: left;
    max-width: none;
  }

  p{
    margin: 0;
  }
}

.site-footer__right{
  width: 40%;

  @include breakpointMax($mobile-medium) {
    width: 100%;
  }
}



