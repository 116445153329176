.stay-connected{

  .ctn-main{
    width: $container-width-with-padding;

    & > a {
      margin-top: 30px;
    }

  }

  img{
    max-width: 100%;
  }

  .img-ctn {
    margin-top: 40px;
  }


  .ctn-main > .col-ctn {
    & > .col-1-3, & > .col-1-2 {
      z-index: 1;
      position: relative;
      margin-bottom: 20px;
      box-shadow: 0 0 4px 0 rgba(#000000, 0.18);
      background-color: $white;
      padding: 15px;

    }
  }

  .ctn-main .description{
    font-size:1.8rem;
    line-height: 26px;

    @include breakpointMax($smaller-devices) {
      font-size:1.4rem;
      line-height: 20px;
    }
  }

  .ctn-main > .col-ctn{
    position: relative;
    margin-top: 75px;
    margin-bottom: 10px;

    /*&:after{
      content: "";
      position: absolute;
      // background: url(assets/img/stay-connected-bg-right.png) top left no-repeat;
      background: url(assets/img/stay-connected-bg-right.svg) top left no-repeat;
      bottom: -90px;
      right: -240px;
      width: 415px;
      height: 354px;
    }*/

    @include breakpointMax($smaller-devices) {
      &:before,
      &:after{
        display: none;
      }
    }

    /*&:before{
      content: "";
      position: absolute;
      // background: url(assets/img/stay-connected.svg) top left no-repeat;
      top: -30px;
      // left: -116px;
      left: -160px;
      width: 294px;
      height: 265px;
      background: url(assets/img/stay-connected-bg-left-2.svg) top left no-repeat;
    }*/
  }

  h3{
    line-height: 28px;
    @include font-size(2);

    @include breakpointMax($smaller-devices) {
      margin-top: 10px;
      line-height: 24px;
      @include font-size(1.8);
    }
  }

  p{
    line-height: 22px;
    @include font-size(1.4);

    @include breakpointMax($smaller-devices) {
      margin-top: 5px;
      line-height: 18px;
      @include font-size(1.2);
    }
  }

  .-no-radius {
    img {
      width: 100%;
      max-width: 70px;
      border-radius: 0;
    }

    .empty-box {
      height: 14px;
      margin-right: auto;
      margin-left: auto;
      background-color: $color-main;

      &.-name {
        width: 50%;
        margin-top: 3px;
      }

      &.-hashtag {
        width: 70%;
        margin-top: 4px;
      }
    }
  }
}

.stay-connected__bottom{
  align-self: flex-end;
  width: 100%;
  word-wrap: break-word;
  line-height: 16px;

  @include font-size(1.2);

  img{
    max-width: 100px;
    border-radius: 50%;
  }
}

.stay-connected__date{
  font-family: $font-accent;
  line-height: 16px;
  @include font-size(1.4);

  @include breakpointMax($smaller-devices) {
    @include font-size(1.2);
  }
}

.stay-connected__video-ctn{
  position: relative;
  margin: 35px 0 0;
  cursor: pointer;
  img{
    width: 100%;
    max-width: none;
  }

  &:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 66px;
    background: url("assets/img/play-btn.svg") no-repeat;
    background-size: 66px;
    cursor: pointer;
  }
}
