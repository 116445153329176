.hear-from-community{

  .slick-slider{
    margin-top: 28px;
  }

  .slick-track{
    display: flex;
    justify-content: flex-start;
  }

  .slick-slide{
    padding-top: 19px;
    overflow: visible;
  }

  .slide-ctn{
    box-shadow: 0 0 4px 0 rgba(#000000, 0.18);
    padding: 40px 50px 16px 14px;
    margin: 5px;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: url(assets/img/quote-icon.svg) no-repeat center;
      left: 15px;
      top: 0;
      transform: translateY(-50%);
    }

    p{
      margin: 0;
      @include font-size(1.4);
      line-height: 18px;
    }

    .author{
      margin-top: 20px;
      @include font-size(1.2);
      line-height: 16px;
      display: block;

      &:before{
        content: "";
        background: $color-line;
        width: 37px;
        height: 2px;
        display: block;
        margin-bottom: 9px;
      }
    }
  }

  .col-slider:nth-child(3n+1) .slide-ctn:before{
    background-color: $color-main;
  }
  .col-slider:nth-child(3n+2) .slide-ctn:before{
    background-color: $color-accent;
  }
  .col-slider:nth-child(3n+3) .slide-ctn:before{
    background-color: $color-light;
  }
}
