@font-face{
  font-family:"HelveticaNeue";
  src:url("../../assets/webfonts/helveticaNeue/HelveticaNeue.eot?#iefix");
  src:url("../../assets/webfonts/helveticaNeue/HelveticaNeue.eot?#iefix") format("eot"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue.woff2") format("woff2"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue.woff") format("woff"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue.ttf") format("truetype");
}

@font-face{
  font-family:"HelveticaNeue-Bold";
  src:url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.eot?#iefix");
  src:url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.eot?#iefix") format("eot"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.woff2") format("woff2"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.woff") format("woff"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.ttf") format("truetype");
}

@font-face{
  font-family:"HelveticaNeue-Italic";
  src:url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.eot?#iefix");
  src:url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.eot?#iefix") format("eot"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.woff2") format("woff2"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.woff") format("woff"),url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.ttf") format("truetype");
}

@font-face{
  font-family:"Maxx-Regular";
  src:url("../../assets/webfonts/Maxx/Maxx-Regular.ttf");
  src:url("../../assets/webfonts/Maxx/Maxx-Regular.ttf") format("truetype"),url("../../assets/webfonts/Maxx/Maxx-Regular.otf") format("otf"),url("../../assets/webfonts/Maxx/Maxx-Regular2.otf") format("otf") ;
}

@font-face{
  font-family:"Maxx-Light";
  src:url("../../assets/webfonts/Maxx/Maxx-Light.ttf");
  src:url("../../assets/webfonts/Maxx/Maxx-Light.ttf") format("truetype"),url("../../assets/webfonts/Maxx/Maxx-Light.otf") format("otf"),url("../../assets/webfonts/Maxx/Maxx-Light2.otf") format("otf");
}

@font-face{
  font-family:"Maxx-Medium";
  src:url("../../assets/webfonts/Maxx/Maxx-Medium.ttf");
  src:url("../../assets/webfonts/Maxx/Maxx-Medium.ttf") format("truetype"),url("../../assets/webfonts/Maxx/Maxx-Medium.otf") format("otf"),url("../../assets/webfonts/Maxx/Maxx-Medium2.otf") format("otf");
}

@font-face{
  font-family:"Maxx-Bold";
  src:url("../../assets/webfonts/Maxx/Maxx-Bold.ttf");
  src:url("../../assets/webfonts/Maxx/Maxx-Bold.ttf") format("truetype"),url("../../assets/webfonts/Maxx/Maxx-Bold.otf") format("otf"),url("../../assets/webfonts/Maxx/Maxx-Bold2.otf") format("otf");
}