// Past initiatives
// - - - - - - - - - - - - -

.past-initiatives {
  margin-bottom: 0;
  background-color: $color-bg-light;
  padding-top: 80px;
  padding-bottom: 80px;

  @include breakpointMax($mobile-small) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .ctn-main {
    width: 100%;
    max-width: 688px;
}

  h2 {
    font-family: 'Maxx-Bold';
    @include font-size(7.2);
    line-height: 72px;
    text-align: center;

    @include breakpointMin($mobile-small) {
      @include font-size(9);
      line-height: 90px;
    }
  }

  .past {
      display: none;
  }

  @include breakpointMin($mobile-small) {
      .past-mobile {
          display: none;
      }

      .past {
          display: block;
      }
  }

  p {
    @include font-size(1.8);
    line-height: 26px;
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    color: $burgundy;

    @include breakpointMin($mobile) {
      @include font-size(2);
      line-height: 28px;
      color: $black;
    }
  }



  // - - - Slider - - -
  .slider {
    // Align the slider correctly.
    // This remove the padding between slides.
    // But just for the left and the right of the slider.
  }

  .slick-slider {

    &.slider-images {
      margin-bottom: 16px;
      
      @include breakpointMax($mobile) {
        display: flex;
        flex-direction: column-reverse;
      }

      .col-slider {
        width: auto;
        padding: 0 75px;

        @include breakpointMax($mobile-small) {
          padding: 0 20px;
        }

        img {
          max-width: 100%;
          width: calc(100vw - 40px);
          height: auto;
          object-fit: contain;

          @include breakpointMin($mobile-small) {
            width: auto;
            height: 497px;
          }
        }
      }

      .slick-slide {
        opacity: 0.2;
        transform: scale(0.9);
        transition: transform 0.5s ease-out;

        &.slick-current {
          opacity: 1;
          transform: none;
        }
      }

      .slick-dots {
        width: auto;
        margin-top: 0;
        margin-bottom: 16px;

        @include breakpointMax($mobile) {

        }

        li {
          margin-top: 19px;
          margin-bottom: 19px;
        }
      }
    }

    &.slider-texts {
      margin: 0 auto;
      width: 570px;
      max-width: 100%;

      .texts-ctn {
        @include breakpointMax($mobile) {
          margin: 0 20px;
        } 
      }

      .col-slider {
        width: 100%;
      }
    }
  }


  // - - - Arrows - - -
  .arrows {
    display: flex;
    position: relative;
    align-items: center;
    gap: 12px 32px;
    margin: 0 auto 0;
    justify-content: space-between;
    height: 0;

    @include breakpointMin($mobile-up) {
      right: 120px;
    }

    button {
      position: static;
      z-index: 1;

      &:hover {
        cursor: pointer;
      }
    }
  }


  // - - - Copy - - -

  .texts-ctn {
    display: flex;
    align-items: center;

    span {
      display: block;
      color: $color-main;
      font-family: $font-main;
    }

    p {
      margin: 0;
      padding: 16px 0 0 0;
      line-height: 22px;
      @include font-size(1.6);
      color: $burgundy;

      @include breakpointMin($mobile) {
        @include font-size(1.8);
        line-height: 25px;
        color: $black;
      }
    }
  }

}

// Disclaimer
.disclaimer {
  background-color: $white;
  padding: 64px 20px;

  @include breakpointMin($mobile-small) {
    display: none;
  }

  p {
    @include font-size(1.3);
    line-height: 1.6em;
    text-align: center;
    margin: 0;
    font-family: 'Maxx-Light';
  }
}
