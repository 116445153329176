.the-program{

  .subtitle{
    font-family: $font-accent;
  }

  .col-ctn{
    margin-top: 36px;
  }

  p{
    margin-top: 12px;
    padding-right: 29px; //avoid widow word

    @include breakpointMax($mobile-small) {
      padding-right: 0;
    }
  }

  img{
    display: block;
    margin-bottom: 15px;

    @include breakpointMax($mobile) {
      max-width: 75px;
    }
  }

  @include breakpointMax($mobile) {
    .col-1-3{
      margin-bottom: 10px !important;
    }
  }
  @include breakpointMax($mobile-small) {
    .col-1-3{
      max-width: 350px;
    }
  }

  .-passed{
    opacity: 0.32;
  }
}
