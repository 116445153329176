.howtoclaim {
    margin-bottom: 0;
    background-color: $color-red;
    padding: 20px;
    padding-bottom: 56px;


    @include breakpointMin($mobile) {
        padding: 56px;
    }

    .ctn-main {
        width: 100%;
        max-width: 1208px;
        margin: 0 auto;

        @include breakpointMin(769px) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            column-gap: 40px;
        }

        @include breakpointMin(1439px) {
            column-gap: 123px;
        }
    }
    .left-col {
        width: 100%;

        img {
            width: 100%;
            cursor: pointer;
        }
        img#howtoclaim {
            display: none;
        }

        @include breakpointMin($mobile) {
            img#howtoclaim {
                display: block;
            }

            img#howtoclaim-mobile {
                display: none;
            }
        }
    }

    .right-col {
        text-align: left;
        color: $white;
        margin-top: 32px;

        @include breakpointMin($mobile) {
            margin-top: 0;
        }
    }

    .pink-text {
        color: $ballet-pink;
    }


    h2 {
        font-family: 'Maxx-Bold';
        @include font-size(4);
        line-height: 40px;

        @include breakpointMin($mobile-small) {
            @include font-size(6.1);
            line-height: 61px;
        }
    }

    p {
        @include font-size(1.8);
        line-height: 26px;

        @include breakpointMin($mobile) {
            @include font-size(2);
            line-height: 28px;
        }
    }

    .button {
        background-color: $white;
        border-radius: 130px;
        width: max-content;
        max-width: 292px;
        padding: 24px 32px;
        a {
            color: $black;
            @include font-size(2);
            line-height: 22px;
            text-wrap: nowrap;
            font-family: 'Maxx-Bold';
            cursor: pointer;
        }

    }

}