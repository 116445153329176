.hero-banner {
  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;

  @include breakpointMax($mobile-small) {
    margin-bottom: 65px;
  }

  img{
    display: block;
    width: 100%;
  }

  .col-2-5.-v-align img{
    max-width: 415px;
    padding: 50px 0;

    @include breakpointMax($mobile-small) {
      width: 100%;
      margin: 0 auto;
      padding: 50px 0 0;
    }
  }

  .ctn-main{
    position: relative;
    // padding: 0 !important;
  }

  .img-ctn{
    position: relative;

    @include breakpointMax($smaller-devices) {
      &:before,
      &:after{
        display: none;
      }
    }
    // z-index: 2;

    /*&:before{
      content: "";
      z-index: -1;
      position: absolute;
      // top: -30px;
      bottom: 10px;
      // left: -116px;
      // left: -160px;
      left: -160px;
      width: 294px;
      height: 265px;
      // background: url(assets/img/stay-connected-bg-left-2.svg) top left no-repeat;
      // background: url(assets/img/stay-connected.svg) top left no-repeat;
      background: url(assets/img/banner-bg-left.svg) top left no-repeat;
    }*/

    /*&:after{
      content: "";
      z-index: -1;
      position: absolute;
      // bottom: -90px;
      top: -73px;
      right: -315px;
      width: 415px;
      height: 354px;
      // background: url(assets/img/stay-connected-bg-right.png) top left no-repeat;
      // background: url(assets/img/stay-connected-bg-right.svg) top left no-repeat;
      background: url(assets/img/banner-bg-right.svg) top left no-repeat;
    }*/
  }

  .video-banner-img{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    // pointer-events: none;

    &:before {
      content: "";
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      background: url("assets/img/play-btn.svg") no-repeat;
      background-size: 100px;

      @include breakpointMax($mobile) {
        width: 80px;
        height: 80px;
        background-size: 80px;
      }

      @include breakpointMax($mobile-small) {
        width: 60px;
        height: 60px;
        background-size: 60px;
      }
    }

    img{
      width: 100%;
      max-width: inherit;
      height: 100%;
      object-fit: cover;
    }
  }

  .tjmaxx-logo{
    z-index: 1;
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 150px !important;

    @include breakpointMax($mobile-small) {
      right: 15px;
      bottom: 15px;
      width: 100px !important;
    }
  }

  .hero-banner__copy-bg {
    background-color: $phase2-darkred;
    padding: 10px;
  }

  .hero-banner__copy{
    background-color: $white;

    img{
      width: 290px;

      @include breakpointMax($mobile-small) {
        width: 90%;
      }
    }

    p{
      margin: 0;
      line-height: 24px;
      @include font-size(1.6);

      @include breakpointMax($mobile) {
        line-height: 25px;
        @include font-size(1.8);
      }

      @include breakpointMax($mobile-small) {
        margin: 0 0 20px;
      }
    }

    h1 {
      margin-bottom: 25px;
      font-family: $font-accent;
      line-height: 45px;
      @include font-size(3.2);

      @include breakpointMax($mobile) {
        line-height: 39px;
        @include font-size(3.0);
      }
    }

    /*&.-img{
      position: absolute;
      left: 50px;
      bottom: 0;
      padding: 35px 35px 25px;
      max-width: 355px;

      @include breakpointMax($mobile-small) {
        position: static;
        padding: 20px;
        max-width: none;
      }

      .video-container{
        display: none;
      }
    }*/

    &.-bottom-box{
      display: flex;
      align-items: center;
      border: 10px solid $phase2-red;;
      padding: 68px 48px;

      @include breakpointMax($mobile-small) {
        display: block;
      }

      @include breakpointMax($mobile-medium) {
        padding: 25px;
      }

      h1 {
        margin-right: 30px;
      }

      /*p{
        padding-right: 66px;
        flex: 1;
        padding-right: 66px;

        @include breakpointMax($mobile-medium) {
          padding-right: 30px;
        }

        @include breakpointMax($mobile-small) {
          padding-right: 0;
        }
      }*/

      .button{
        display: block;
        align-self: center;
        width: 121px;
        padding: 15px 10px;
        text-align: center;
      }
    }
  }
}
