.faq{

  & >.ctn-main{
    background-color: rgba($color-main, 0.05);
    padding: 50px 100px;

    @include breakpointMax($mobile-small) {
      padding: 50px 20px;
    }
  }

}

// ------------------------------------------------
// Accordion
// ------------------------------------------------

.accordion-wrapper {
  margin-top: 60px;

  ul {
    margin: 0;
  }

  li{
    margin-left: 0;
  }

  .accordion {
    margin-bottom: 20px;
    background-color: $white;
    box-shadow: 0 0 4px 0 rgba(#000000, 0.18);
    list-style-type: none;
    padding: 0 20px;
    border-radius: 2px;
  }

  .accordion-title{
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-family: $font-accent;
  }

  .accordion-button-ctn {
    display: inline-block;
    width: 75px;
    margin-left: auto;
    text-align: right;
    position: relative;

    &:before,
    &:after{
      content: "";
      position: absolute;
      background-color: #e2272f;
      top: 50%;
      left: calc(100% - 22px);
      border-radius: 4px;
      transition: all .2s ease-in-out;
      transform: rotate( -90deg );
    }

    &:before {
      width: 4px;
      height: 22px;
      margin-left: -2px;
      margin-top: -11px;
    }

    &:after {
      width: 22px;
      height: 4px;
      margin-left: -11px;
      margin-top: -2px;
    }
  }

  .content {
    display: none;
    padding-bottom: 20px;

    p, ul {
      margin-left: 28px;
      line-height: 24px;
      @include font-size(1.6);
    }
  }

  .accordion.expanded {
    .accordion-button-ctn {

      &:before,
      &after {
        transition: all .2s ease-in-out;
        transform: rotate( 90deg );
      }

      &:after {
        opacity: 0;
      }
    }
  }

  .container-btn {
    cursor: pointer;
  }

}
