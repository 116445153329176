.the-community{

  .col-2-5{
    width: calc((100% / 5 * 2) - 8px);
  }

  @include breakpointMax($mobile-small) {
    .col-3-5{
        margin-left: -60px;
        margin-right: -90px;
        width: calc(100vw + 150px) !important;
    }
  }

  img{
    max-width: 100%;
  }
}
