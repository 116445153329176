// Partnerships
// - - - - - - - - - - - - -

.partnerships {
  margin-bottom: 0;
  padding-top: 50px;

  .ctn-main {
      width: 100%;
      max-width: 738px;
  }

  h2 {
      font-family: 'Maxx-Bold';
      @include font-size(7.2);
      line-height: 72px;
      text-align: center;

      @include breakpointMin($mobile-small) {
          @include font-size(9);
          line-height: 90px;
      }
  }

  p {
      @include font-size(1.8);
      line-height: 26px;
      text-align: center;

      @include breakpointMin($mobile) {
          @include font-size(2);
          line-height: 28px;
      }
  }

  .partner {
      display: none;
  }

  @include breakpointMin($mobile-small) {
      .partner-mobile {
          display: none;
      }

      .partner {
          display: block;
      }
  }

  .partner-top {
      margin-bottom: 40px;

      @include breakpointMin($mobile-small) {
          margin-bottom: 80px;
      }

  }

  .partner-ctn {
      color: $white;

      @include breakpointMin($mobile-small) {
          display: flex;
          justify-content: center;
      }

      .partner-col {
          width: 100%;
          padding: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          @include breakpointMin(1441px) {
              align-items: center;
          }

          img {
              width: 100%;
              max-width: 300px;
              margin-bottom: 10px;

              @include breakpointMin($mobile-small) {
                  transform: none;
              }
              @include breakpointMin(1441px) {
                  transform: translateX(-70px);
              }
          }

          p {
              max-width: 450px;
              text-align: left;
              margin: 0;
          }

          @include breakpointMin($mobile) {
              padding: 100px;
          }

      }
      .partner-1 {
          background-color: #A6192E;
      }

      .partner-2 {
          background-color: #691B32;
      }

      a {
          color: white;
          text-decoration: underline;

          &:hover {
              background: none;
          }
      }
  }


  .bottom-section {
      background-color: $light-pink;
      padding: 60px 0;


      .partner-bottom {
        max-width: 900px;

        @include breakpointMin($mobile-small) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h3 {
            @include font-size(4);
            margin-bottom: 20px;
            line-height: 40px;
            text-align: center;
            font-family: 'Maxx-Bold';

            @include breakpointMin($mobile-small) {
                display: none;
            }
        }
  
        img {
            display : none;
        }

        p {
          margin: 0;
          @include font-size(1.8);
          line-height: 26px;
          text-align: center;

          @include breakpointMin($mobile-small) {
              @include font-size(2);
              line-height: 28px;
              text-align: left;
          }
        }
  
        @include breakpointMin($mobile-small) {
            img {
                display : block;
                max-width: 214px;
            }
        }
    }
  }



}