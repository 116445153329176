// how to use fadeInFromNone
// use @include anim_fadeInFromNone;
@keyframes fadeInFromNone {
  0% {
    opacity: 0;
    display: none;
  }

  1% {
    opacity: 0;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@mixin anim_fadeInFromNone {
  animation: fadeInFromNone 0.5s ease-out;
}

@mixin anim_transition($duration, $delay: 0ms) {
  transition: $duration cubic-bezier(0.215, 0.61, 0.355, 1) $delay;
}

//smooth element appearance animation
@mixin anim_showElementPre($duration: 1s, $delay: 0ms) {
  @include anim_transition($duration, $delay);
  opacity: 0;
  transform: translateY(30px);
}
@mixin anim_showElementPost {
  opacity: 1;
  transform: translateY(0);
}

// ---- smooth element appearance animation -----
// put code below in the relevant sass modules file.
// dont forget to use the .js-animating-block in your html. This is where the class animating will appear
.your-selector {
  @include anim_showElementPre;
  //with duration and or delay control, both optionals. default value is (1s, 0s)
  //to have a delay, you must specify duration first
  //anim_showElementPre(duration, delay);
  //ex: anim_showElementPre(1.5s); VS ex: anim_showElementPre(1s, 0.5s);
}

.animated-block {
  //for whole sections:
  &.your-selector {
    @include anim_showElementPost;
  }
  //or for smaller elements:
  .your-selector {
    @include anim_showElementPost;
  }
}
