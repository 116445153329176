// Why it matters
// - - - - - - - - - - - - -

.why-it-matters {
  margin: 0;
  background-color: $color-bg-light;
  padding: 70px 0;

  .col-ctn {
    align-items: center;

    &:last-child {
      margin-top: 143px;

      @include breakpointMax($mobile-small) {
        margin-top: 107px;
      }

      .col-1-2 {

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  // - - - Copy - - -
  .copy-ctn {
    max-width: 506px;
    margin-right: 14%;
    margin-left: auto;

    @include breakpointMax($mobile-small) {
      margin: 0;
    }

    h2 {
      margin-bottom: 24px;
      line-height: 30px;
      @include font-size(3.2);

      @include breakpointMax($smaller-devices) {
        line-height: 22px;
        @include font-size(2.4);
      }
    }

    p {
      line-height: 32px;
      @include font-size(2.4);

      @include breakpointMax($smaller-devices) {
        line-height: 24px;
        @include font-size(1.8);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  // - - - Image - - -
  .images-ctn {
    position: relative;
    padding-left: 9%;

    &.left {
      padding-right: 9%;
      padding-left: 0;
    }

    img {
      width: 100%;
    }
  }

  .stats-ctn {
    position: absolute;
    top: 50%;
    left: -3%;
    transform: translateY(-50%);
    width: 64%;
    max-width: 440px;
    box-shadow: 5px 5px 50px 0px rgba($black, 0.10);
    background-color: $color-bg-medium;
    padding: 23% 7% 20%;

    @include breakpointMax($mobile-small) {
      left: 0;
    }

    img {
      max-width: 360px;
    }
  }


  // - - - Left image - - -
  .-left {

    .col-1-2 {
      @include breakpointMax($mobile-small) {
        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 1;
        }
      }
    }

    // Copy
    .copy-ctn {
      margin-right: 0;
      margin-left: 14%;

      @include breakpointMax($mobile-small) {
        margin: 0;
      }
    }

    // Image
    .images-ctn {
      padding-right: 9%;
      padding-left: 0;
    }

    .stats-ctn {
      right: -3%;
      left: auto;

      @include breakpointMax($mobile-small) {
        right: 0;
      }
    }
  }
}


