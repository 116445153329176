
/* ==========================================================================
   Slider
   ========================================================================== */
  .slick-slider {
    // position: relative;
    // overflow: hidden;

    // .slick-track{
    //   display: flex;
    //   align-items: flex-start;
    // }

    // .slick-slide{
    //   overflow: hidden;
    // }

    // - - - Bottom Navigation - - -
    .slick-dots{
      @extend .-centered;
      z-index: 2;
      position: static;
      margin-top: 20px;
      list-style: none;

      li {
        display: inline-block;
        width: auto;
        height: auto;
        margin: 9px;

        button {
          width: 9px; // Bullets size
          height: 9px; // Bullets size
          border: 0;
          border-radius: 50%;
          background: #FAC3C1;
          padding: 0;
          cursor: pointer;
          font-size: 0;

          &::before {
            display: none;
          }
        }
      }

      .slick-active {
        button{
          background: #E81D2C;
          pointer-events: none;
        }
      }
    }
  }


  // - - - Arrows outside the slick - - -
  .slick-arrow {
    width: 80px;
    height: 80px;
    cursor: pointer;

    @include breakpointMax($mobile) {
      width: 48px;
      height: 48px;
    }

    &::before {
      display: block;
      width: 80px;
      height: 80px;
      background-size: 80px;

      @include breakpointMax($mobile) {
        width: 48px;
        height: 48px;
        background-size: 48px;
      }
    }
  }

  .slick-prev {
    &::before {
      content: "";
      background-image: url("assets/img/arrows.svg");
    }
  }

  .slick-next {
    &::before {
      content: "";
      transform: rotate(180deg);
      transform-origin: center;
      background-image: url("assets/img/arrows.svg");
    }
  }
