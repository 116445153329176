.error-404 {

  .content {
    &.ctn-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100vh - 105px - 168px);
    }
  }
}
