@media print {
	* {
		color: #000 !important;
	}

	body {
		background-color: #fff;
		font-size: 12pt; 
	}

	img {
		max-width: 300px;
	}

	.site-content {
		overflow: visible;
		padding-bottom: 300px;

	}
	.box{
		position: static !important;
		transform: none !important;
		max-width: none !important;
	}
	.img-ctn{
		position: static !important;
	}
	.steps{
		.col-1{
			page-break-inside: avoid !important;
		}
		.acc-title{
			display: none;
		}
		.acc-list{
			display: block !important;
		}
	}
	*:after,
	*:before{
		display: none !important;
	}

	.block{
		display: block !important;
		margin-bottom: 50px !important;
	}
	.text-ctn,
	.col-ctn{
		display: block !important;
		width: 100% !important;
	}

	.content-area + .widget-area,
	.widget-tide,
	header,
	footer {
		display: none !important;
	}
	.site-footer{
		display: none;
	}
	.print-buttons{
		display: none;
	}
	.button {
		display: none;
	}
}


body.printing {
	.text-centered {
		text-align: left !important;
	}
}