// Resources - Claim Your &
// - - - - - - - - - - - - -

.resources-claimyour {
  margin-bottom: 0;
  background-color: $color-red;
  color: $white;
  padding: 80px 0;

  @include breakpointMax($mobile) {
    padding: 64px 0;
  }

  .top {
    margin-bottom: 40px;
    margin: auto;
    text-align: center;
    max-width: 640px;

    @include breakpointMin($mobile) {
      margin-bottom: 56px;
    }

    h2 {
      margin-bottom: 24px;
      line-height: 40px;
      @include font-size(4);
      font-family: 'Maxx-Bold';
  
      @include breakpointMin($mobile) {
        line-height: 61px;
        @include font-size(6.1);
      }
    }

    p {
      line-height: 27px;
      @include font-size(1.8);
      text-align: center;
    }
  }

  .bottom {
    margin: 0 auto;
    text-align: center;

    .grid {

      .grid__item.js-load-more-items {
        display: none;

        &.--show {
          display: block;

          @include breakpointMin(769px) {
            display: flex;
          }
        }
      }

      @include breakpointMin(1000px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
      }

    }

    .grid__item {
      margin-bottom: 24px;
      border: 16px solid $burgundy;
      background-color: $light-pink;
      // height: 516px;

      @include breakpointMin(769px) {
        display: flex;
        align-items: center;
      }

      @include breakpointMin(1000px) {
        max-width: 688px;
      }


      .desktop-image {
        display: none;
      }

      @include breakpointMin(769px) {
        .desktop-image {
          display: block;
        }
        .mobile-image {
          display: none;
        }
      }

      img.grid__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 16px;
        max-width: none;

        @include breakpointMin(769px) {
          margin-bottom: 0;
          width: auto;
          max-width: 35%;
        }
      }

      .grid__item-details {
        padding: 0 16px 24px;

        @include breakpointMin(769px) {
            padding-top: 24px;
          }

        h3 {
          margin-bottom: 16px;
          line-height: 28px;
          @include font-size(2.8);
          font-family: 'Maxx-Bold';
          color: $light-red;
          text-align: left;
          
          @include breakpointMin(769px) {
            line-height: 32px;
            @include font-size(3.1);
          }

          @include breakpointMin(1000px) {
            line-height: 25px;
            @include font-size(2.5);
          }

          @include breakpointMin(1439px) {
            line-height: 30px;
            @include font-size(3.0);
          }
        }
  
        p {
          line-height: 22px;
          @include font-size(1.6);
          text-align: left;
          color: $black;
          margin-bottom: 16px;

          strong {
            font-family: 'Maxx-Medium';
          }

          @include breakpointMin(769px) {
            line-height: 25px;
            @include font-size(1.7);
          }

          @include breakpointMin(1000px) {
            line-height: 20px;
            @include font-size(1.5);
          }

          @include breakpointMin(1439px) {
            line-height: 25px;
            @include font-size(1.7);
          }
        }
  
        .btn {
          display: flex;
          align-items: center;
          column-gap: 8px;
          cursor: pointer;
          border-radius: 80px;
          background-color: $white;
          border: none;
          color: $black;
          padding: 16px 28px;
          @include font-size(1.7);
          line-height: 18px;

          img.play-btn-red {
            width: 14px;
            height: 18px;
          }

          span {
            color: $time-grey;
          }
        }
      }


    }
    
  }

  .button {
    cursor: pointer;
    padding: 22px 32px;
    @include font-size(1.9);
    line-height: 19px;
    font-family: 'Maxx-Bold';
    text-align: center;
    text-wrap: nowrap;
    cursor: pointer;
    color: $black;
    background-color: $white;
    border-radius: 130px;
    width: 172px;
    height: 62px;
  }

}
