.site-header {
  position: relative;
  @include font-size(1.8);
  line-height: 18px;
  font-family: 'Maxx-Medium';
  padding: 20px 0;

  @include breakpointMax($mobile-small) {
    padding-top: 48px;
    padding-bottom: 8px;
  }


	.ctn-main{
		display: flex;
		flex-wrap: wrap;
    width: $container-width-with-padding;
    justify-content: space-between;
    align-items: center;
	}

	.site-header__logo{
    width: 210px;
		cursor: pointer;
		font-size: 0;
		transition: all 1s;

    @include breakpointMax($mobile-small) {
      padding: 0;
    }

    @include breakpointMax($mobile-xsmall) {
      width: auto;
    }

		img{
      display: block;
			width: 100%;
		}
	}


  .site-header__menu{
    .menu__toggle {
      display: none;
      border: none;
      background-color: $white;
      padding: 10px;
      color: $burgundy;
      cursor: pointer;
      width: 34px;
      height: 34px;
  
      @include breakpointMax($mobile-small) {
          display: block;
      }
  }
    .site-header__menu-ul {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;

      li {
        color: $color-main;
        
        >a {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      li > a.is-active {
        color: $black;
        text-decoration: none;
      }

      @include breakpointMax($mobile-small) {
        display: none;
        flex-direction: column;
        row-gap: 16px;
        padding: 16px 0;
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        background-color: $white;

      }
    }

    .site-header__menu-ul.open {
      display: flex;
      z-index: 100;
    }

  }

	// .col-ctn{
  //   justify-content: space-between;
	// 	align-items: center;
	// 	width: 100%;

  //   @include breakpointMax($mobile-small) {
  //     justify-content: flex-start;
  //     overflow: visible;
  //   }
	// }
}

// .menu-toggle{
//   position: relative;
//   min-width: 24px;
//   margin-right: 16px;
//   border: 0 none;
//   border-radius: 0;
//   box-shadow: none;
//   background-color: transparent;
//   padding: 0;
//   cursor: pointer;

//   .icon-bar {
//     display: block;
//     width: 24px;
//     height: 4px;
//     border-radius: 1px;
//     background-color: $color-main;

//     transition: width 0.35s;
//     transition-delay: 0.35s;
//     & + .icon-bar {
//       margin-top: 5px;
//     }
//   }

//   .x-ixon{

//     position: absolute;
//     top: 1px;
//     left: 1px;
//     transform: scale(0);
//     transform-origin: center left;
//     width: 21px;
//     transition: all 0.35s;
//   }

//   &.active{
//     .icon-bar{
//       width: 0;
//       transition-delay: 0s;
//     }
//     .x-ixon{
//       transform: scale(1);
//       transition-delay: 0.35s;
//     }
//   }

//   &:focus,
//   &:active {
//     outline: none;
//     border: 0 none;
//     box-shadow: none;
//   }

// }

// @include breakpointMin($smaller-devices-up) {
//   .menu-toggle,
//   .sub-menu,
//   .footer-menu{
//     display: none;
//   }
// }

// @include breakpointMax($mobile-small) {
//   ul:not(:last-child){
//     margin-bottom: 20px;
//     border-bottom: 2px solid white;
//     padding-bottom: 20px;
//   }

//   .login{
//     display: none !important;
//   }
// }

// .site-header__nav {
//   width: calc(100% - 130px);

//   @include breakpointMax($mobile-small) {
//     z-index: 2;
// 		position: absolute;
// 		top: 100%;
// 		left: 0;
//     transform: translateX(-100%);

//     width: 100%;
//     max-width: 70%;
//     background-color: $color-main;
//     padding: 24px;
//     transition: all 0.75s;

//     .menu{
//       float: none;
//     }

//     li{
//       display: block;
//       margin-left: 0;
//       padding-right: 0;
//       padding-left: 0;
//     }

//     &.active{
//       transform: translateX(0);
//     }
//   }

//   .menu{
//     float: right;
//   }

//   .menu .sub-menu{
//     z-index: 1;
//     display: none;
//     position: absolute;
//     top: 86%;
//     right: -5px;
//     min-width: 90px;
//     border-radius: 5px;
//     background-color: $color-main;
//     padding-top: 3px;
//     padding-bottom: 3px;

//     li{
//       padding-top: 0;
//       padding-bottom: 0;
//     }

//     a {
//       color: $white;
//       text-transform: lowercase;
//       line-height: 27px;
//       @include font-size(1.2);

//       &:hover{
//         color: $black !important;
//       }
//     }
//   }

// 	li{
//     display: inline-block;
//     position: relative;
//     padding: 10px 15px;

//     &:hover{
//       .sub-menu{
//         display: block;
//       }
//     }
// 	}

// 	a{
// 		display: block;
//     padding: 0 2px;
// 		cursor: pointer;
// 		color: $black;
//     letter-spacing: 1.5px;
//     text-transform: uppercase;
// 		text-decoration: none;
//     font-family: $font-accent;
//     font-weight: bold;
// 		font-weight: 500;
//     line-height: 21px;
//     transition: all 0.5s;
//     @include font-size(1.6);

//     @include breakpointMax($mobile-small) {
//       color: $white;
//       text-transform: lowercase;
//       line-height: 18px;
//       @include font-size(1.2);
//     }

//     &:hover{
//       color: $color-main;

//       @include breakpointMax($mobile-small) {
//         color: $white;
//         text-decoration: underline;
//       }
//     }
// 	}

//   li:not(.login)a:hover{
//     background-color: $color-main;
//     color: $white;
//     //text-decoration: underline;
//   }

//   .menu > li:last-child{
//     padding-right: 0;
//   }

//   img{
//     vertical-align: middle;
//   }

//   .footer-menu {
//     li{
//       padding: 0;
//     }

//     a{
//       text-transform: none;
//       text-decoration: underline;
//       line-height: 26px;
//       @include font-size(1);
//     }
//   }
// }
