.women-supporting{
  .button{
    margin-top: 20px;
  }

  .description{
    padding-right: 12px; //avoid widow word

    @include breakpointMax($mobile-small) {
      padding-right: 0;
    }
  }

  @include breakpointMax($mobile-small) {
    .col-3-5{
        margin-left: -40px;
        margin-right: -60px;
        width: calc(100vw + 100px) !important;
    }
  }
}
