.writing-prompts{

  .slide-ctn{
    padding: 50px;
    min-height: 325px;
    display: flex;
    align-items: center;
    //border: 18px solid $color-main;
  }

  @include breakpointMax($mobile) {
    .subtitle{
      text-align: left !important;
    }
  }

  .col-slider{

    &:nth-child(3n+1) .slide-ctn{
      background: url(assets/img/prompts-frame1.jpg) center no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(3n+2) .slide-ctn{
      background: url(assets/img/prompts-frame2.jpg) center no-repeat;
      background-size: 100% 100%;
    }
    &:nth-child(3n+3) .slide-ctn{
      background: url(assets/img/prompts-frame3.jpg) center no-repeat;
      background-size: 100% 100%;
    }
  }

  p{
    margin: 0;

    font-family: $font-accent;

    @include breakpointMax($mobile) {
      @include font-size(1.8);
      line-height: 28px;
    }
  }
}
