// Unwanted labels
// - - - - - - - - - - - - -

.unwanted-labels {
  margin: 0;
  background-color: $color-bg-medium;
  padding: 123px 0 130px;

  @include breakpointMax($mobile-small) {
    padding: 92px 0 97px;
  }


  // - - - Video - - -


  // - - - - - - - - -


  h2 {
    margin-bottom: 34px;
    color: $color-main;
    letter-spacing: -1.95px;
    line-height: 65px;
    @include font-size(6.5);

    @include breakpointMax($smaller-devices) {
      line-height: 49px;
      @include font-size(4.9);
    }
  }

  h3 {
    margin-top: 42px;
    line-height: 30px;
    @include font-size(3.2);

    @include breakpointMax($smaller-devices) {
      line-height: 22px;
      @include font-size(2.4);
    }
  }

  p {
    line-height: 32px;
    @include font-size(2.4);

    @include breakpointMax($smaller-devices) {
      line-height: 24px;
      @include font-size(1.8);
    }
  }


  // - - - Buttons - - -
  .btn-ctn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 31px;
    gap: 12px 28px;

    a {
      display: inline-flex;
      align-items: center;
      line-height: 27px;
      gap: 8px 14px;
    }
  }

  // - - - Colunms - - -
  .col-1-2 {
    &:last-child {
      margin: 0;
    }
  }
}
