// Workshops
// - - - - - - - - - - - - -

.workshops {
  margin-top: 46px;

  h2 {
    color: $color-main;
    letter-spacing: -2.1px;
    line-height: 66px;
    @include font-size(7.0);

    @include breakpointMax($smaller-devices) {
      line-height: 49px;
      @include font-size(5.2);
    }
  }

  // - - - Slider - - -
  .slider {
    // Align the slider correctly.
    // This remove the padding between slides.
    // But just for the left and the right of the slider.
    margin: 0 -10px;

    @include breakpointMax($mobile-small) {
      margin: 0;
    }
  }

  .slick-slider {
    margin-top: 62px;
  }

  .col-slider {
    position: relative;
    width: 100%;
  }

  img {
    width: 100%;
    height: 553px;
    object-fit: cover;
  }


  // - - - Copy - - -
  .copy-ctn {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 174px;
    margin: 0 10px;
    background-color: $color-main;
    padding: 21px 29px 19px;

    @include breakpointMax($mobile-small) {
      margin: 0;
    }

    h3 {
      margin-bottom: 20px;
      color: $white;
      line-height: 34px;
      @include font-size(2.9);

      &:last-child {
        margin-bottom: 0;
      }
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      color: $white;
      letter-spacing: 3.2px;
      text-transform: none;
      line-height: 27px;
      gap: 8px 14px;
      @include font-size(1.3);

      img {
        width: 27px;
        height: auto;
      }
    }

    span {
      display: inline-block;
      margin-left: 41px;
      border-radius: 20px;
      background-color: $color-red;
      padding: 2px 9px 2px 12px;
      color: $white;
      letter-spacing: 2px;
      text-align: center;
      line-height: 20px;
      @include font-size(1.0);
    }
  }
}
