.intro-section {
    background-color: $color-bg-light;
    padding: 72px 20px 64px 20px;
    margin-bottom: 0;

    .ctn-main {
        width: 100%;
        max-width: 925px;
    }

    @include breakpointMin($mobile) {
        padding: 72px 150px;
        margin-bottom: 0;
    }

    @include breakpointMin($mobile-small) {
        margin-bottom: 0;
    }

    .intro__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        pointer-events: none;
    }

    .intro {
        display: none;
    }

    @include breakpointMin($mobile-small) {
        .intro-mobile {
            display: none;
        }

        .intro {
            display: block;
        }
    }

    .red-text {
        color: $color-main;
        font-family: 'Maxx-Bold';
    }

    h2 {
        font-family: 'Maxx-Bold';
        text-align: center;
        @include font-size(6.1);
        line-height: 61px;
        text-wrap: nowrap;

        @include breakpointMax($mobile-small) {
            @include font-size(4.8);
            line-height: 48px;
            text-wrap: wrap;
        }

        span.red-text {
            font-family: 'Maxx-Bold';
        }
    }

    .col-ctn {
        margin-top: 40px;
        margin-bottom: 40px;

        @include breakpointMax($mobile-small) {
            margin-top: 32px;
            margin-bottom: 30px;
            row-gap: 24px;
        }
    }

    p {
        @include font-size(1.8);
        line-height: 26px;
        margin: 0;

        @include breakpointMin($mobile) {
            @include font-size(2);
            line-height: 28px;
        }
    }

    h3 {
        font-family: 'Maxx-Regular';
        @include font-size(2.4);
        margin-bottom: 40px;
        line-height: 29px;

        @include breakpointMin($mobile-small) {
            text-align: center;
        }
    }

    .--disclaimer {
      max-width: 640px;
      margin: 0 auto;

      p {
        @include font-size(1.2);
        line-height: 1.5em;
        margin-top: 40px;
        margin-bottom: 0;
        text-align: center;
      }
    }
}