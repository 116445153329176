.who-is-involved{

  .col-ctn{
    padding: 0 20px;
    margin-top: 60px;

    @include breakpointMax($mobile) {
      padding: 0;
      margin-top: 0;
    }
  }

  .col-1-2{
    width: calc(50% - 40px);
  }

  .celeb__img-ctn{
    background: url(assets/img/celeb-striped-bg.png) center no-repeat;
    background-size: 100% auto;
    padding: 0 60px;
    min-height: 160px;

    @include breakpointMax($mobile) {
      padding: 0 35px;
    }

    img{
      width: 100%;
    }
  }
  .celeb__text-ctn{
    padding: 0 60px;
    @include font-size(1.6);
    line-height: 21px;

    @include breakpointMax($mobile) {
      padding: 0 35px;
      @include font-size(1.4);
      line-height: 21px;

      p{
        margin-top: 5px;
      }
    }
  }

  .hidden-name{
    margin-top: 20px;
    width: 150px;

    @include breakpointMax($mobile-small) {
      width: auto;
    }
  }
}
