.resources-hero {
    background-color: $color-main;
    color: $white;
    padding: 80px 0;
    margin-bottom: 0;

    @include breakpointMax($mobile-small) {
        padding: 64px 0;
    }

    .ctn-main {
        width: 100%;
        max-width: 790px;
    }

    h2 {
        font-family: 'Maxx-Bold';
        @include font-size(4.8);
        line-height: 48px;
        text-align: center;
        margin-bottom: 24px;
        text-transform: lowercase;

        @include breakpointMin($mobile) {
            @include font-size(6.1);
            line-height: 61px;
        }
    }

    p {
        @include font-size(2.4);
        line-height: 29px;
        text-align: center;
        margin: 0;
        font-family: 'Maxx-Light';

        @include breakpointMin($mobile) {
            @include font-size(2.4);
            line-height: 28px;
        }
    }

}