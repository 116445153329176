// Resources: Past Initiative
// - - - - - - - - - - - - -

.resources-pastinitiative {
  margin-bottom: 0;
  background-color: $color-bg-light;
  color: $black;
  padding: 80px 56px;

  @include breakpointMax($mobile-small) {
    padding: 64px 0 40px 0;
  }

  // - - - Copy - - -
  .copy-ctn {
    // max-width: 451px;
    text-align: left;

    @include breakpointMin($mobile) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      margin-bottom: 27px;
      line-height: 49px;
      @include font-size(5.2);
      font-family: 'Maxx-Bold';

      @include breakpointMin($mobile) {
        line-height: 61px;
        @include font-size(6.1);
      }
    }
  }


  // - - - Slider - - -
  .slider {
    // Align the slider correctly.
    // This remove the padding between slides.
    // But just for the left and the right of the slider.
    margin: 0 -10px;
  }

  .slick-slider {

    .desktop-image {
      display: none;
    }

    @include breakpointMin(1000px) {
      .desktop-image {
        display: block;
      }
      .mobile-image {
        display: none;
      }
    }

    .slick-slide > div { 
      margin: 0 10px;
    }

    &.slider-images {
      margin-bottom: 16px;
      cursor: pointer;

      .col-slider {
        padding: 0;
        border: 1px solid $time-grey;

        @include breakpointMin($mobile) {
          display: flex !important;
          align-items: start;
          max-width: 450px;
        }

        img.col__image {
          width: 100%;
          height: 100%;
          max-height: 200px;
          object-fit: cover;
          margin-bottom: 16px;



          @include breakpointMin($mobile) {
            margin-bottom: 0;
            max-height: none;
          }
        }
      }
    }

    .slider__details {
      padding: 0 16px 24px;

      @include breakpointMin($mobile) {
        padding: 24px;
      }

      .download > a {
        text-decoration: underline;
        color: $black;
      }

      h6 {
        margin-bottom: 4px;
        line-height: 14px;
        @include font-size(1.3);
        font-family: 'Maxx-Regular';
        color: $burgundy;
        text-align: left;
      }

      h3 {
        margin-bottom: 16px;
        line-height: 28px;
        @include font-size(2.8);
        font-family: 'Maxx-Bold';
        color: $light-red;
        text-align: left;
        
        @include breakpointMin($mobile) {
          line-height: 32px;
          @include font-size(3.1);
        }
      }

      p {
        line-height: 22px;
        @include font-size(1.6);
        text-align: left;
        color: $black;
        opacity: 0.7;
        margin-bottom: 16px;

        strong {
          font-family: 'Maxx-Medium';
        }

        @include breakpointMin($mobile) {
          line-height: 25px;
          @include font-size(1.7);
        }
      }

      .btn {
        display: flex;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;
        border-radius: 80px;
        background-color: $white;
        border: 1px solid $phase2-darkred;
        color: $black;
        padding: 16px 28px;
        @include font-size(1.7);
        line-height: 18px;

        img.play-btn-red {
          width: 14px;
          height: 18px;
        }

        span {
          color: $time-grey;
        }
      }
    }

  }


  // - - - Arrows - - -
  .arrows {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-top: 48px;
    // gap: 12px 32px;

    @include breakpointMin($mobile) {
      justify-content: center;
      gap: 24px;
    }

    button {
      position: static;
    }

    .slick-prev {
      z-index: 1;
    }
  }

  .slick-dots {

    @include breakpointMax($mobile) {
      bottom: 35px !important;
      left: 0 !important;

      li {
        display: inline-block;
        width: auto;
        height: auto;
        margin: 9px;

        button {
          width: 9px; // Bullets size
          height: 9px; // Bullets size
          border: 0;
          border-radius: 50%;
          background: #FAC3C1;
          padding: 0;
          cursor: pointer;
          font-size: 0;

          &::before {
            display: none;
          }
        }
      }

      .slick-active {
        button{
          background: #E81D2C;
          pointer-events: none;
        }
      }
    }
  }
}
