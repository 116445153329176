.the-research{

  .ctn-main {
    width: $container-width-with-padding;

    & >.description {
      padding-right: 42px; //avoid widow word -_-
    }
  }
}

.stat-ctn{
  position: relative;
  width: 100%;
  height: 610px;

  @include breakpointMax($mobile-medium) {
    height: 500px;
  }

  @include breakpointMax($mobile-small) {
    height: auto;
  }
}

.stat-item{
  position: absolute;
  @include breakpointMax($mobile-small) {
    position: relative;
  }

  img{
    @include breakpointMax($mobile) {
      width: 90%;
    }
    @include breakpointMax($mobile-medium) {
      width: 75%;
    }

    @include breakpointMax($mobile-small) {
      width: 100%;
    }
  }


  &.stat-1{
    top: 0;
    right: 55px;
    @include breakpointMax($mobile-medium) {
      right: 0;
    }

    @include breakpointMax($mobile-small) {
      right: auto;
      left: 0;
      width: 236px;
      margin-bottom: 20px;
    }
  }

  &.stat-2{
    top: 32px;
    left: 0;

    @include breakpointMax($mobile-small) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 278px;
      margin-bottom: 50px;
    }
  }

  &.stat-3{
    bottom: 0;
    left: 47%;

    @include breakpointMax($mobile-small) {
      top: 0;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 190px;
      margin-bottom: 40px;
    }
  }
}

.stat-source{
  position: absolute;
  right: 0;
  bottom: 17%;
  width: 155px;
  line-height: 22px;
  @include font-size(1.4);

  @include breakpointMax($mobile) {
    bottom: 24%;
  }

  @include breakpointMax($mobile-medium) {
    line-height: 16px;
    @include font-size(1);
  }

  @include breakpointMax($mobile-small) {
    position: relative;
    width: 100%;
  }
}

.stat-item__copy-box{
  z-index: 1;
  position: absolute;
  box-shadow: 0 0 4px 0 rgba(#000000, 0.18);
  background-color: $white;
  padding: 15px;
  line-height: 22px;
  @include font-size(1.6);

  @include breakpointMax($mobile-medium) {
    line-height: 18px;
    @include font-size(1.2);
  }

  .stat-1 &{
    top: 36.5%;
    right: -16%;
    width: 292px;

    @include breakpointMax($mobile-medium) {
      right: 0;
    }

    @include breakpointMax($mobile-small) {
      top: 30%;
      right: -23%;
      width: 210px;
    }
  }
  .stat-2 &{
    bottom: -6%;
    left: 0;
    width: 262px;

    @include breakpointMax($mobile-small) {
      right: -16%;
      width: 190px;
    }
  }
  .stat-3 &{
    top: 46.5%;
    right: -19%;
    width: 188px;

    @include breakpointMax($mobile-small) {
      right: -16%;
      width: 148px;
    }
  }
}
