.get-inspired{

  .description{
    margin-bottom: 40px;
    font-size:1.8rem;
    line-height: 26px;

    @include breakpointMax($smaller-devices) {
      font-size:1.4rem;
      line-height: 20px;
    }
  }

  .ctn-main > .col-ctn > .col-1-1, .ctn-main > .col-ctn > .col-1-2{
    z-index: 1;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 0 4px 0 rgba(#000000, 0.18);
    background-color: $white;
    padding: 40px 50px 15px;

    @include breakpointMax($mobile-xsmall) {
      padding: 30px 30px 40px;
    }
  }

  .col-1-1 {
    width: 100%;
    padding-right: 15px;

    .get-inspired__video-ctn {
      margin: 35px -35px 0 15px; 
    }

    @include breakpointMax($mobile-small) {
      .col-1-2 {
          width: 100%;

          .get-inspired__video-ctn {
            margin-left: -35px;
          }
      }
    }
  }

  span{
    display: block;
  }

  .date{
    font-family: $font-accent;
    line-height: 16px;
    @include font-size(1.4);
  }

  h3{
    color: $color-main;
  }

  h4{
    margin-bottom: 10px;
    font-family: $font-accent;
    line-height: 20px;
    @include font-size(1.6);
  }

  p{
    line-height: 22px;
    @include font-size(1.4);
  }

  img {
    border-radius: 50%;
  }

  .col-ctn .col-ctn{
    align-items: center;
  }

  .title{
    margin: 0;
    line-height: 16px;
    @include font-size(1.2);
  }

  .social{
    margin-top: 15px;
    line-height: 12px;
    @include font-size(1.2);
  }

  .button{
    margin-top: 20px;
  }
}

.get-inspired__video-ctn{
  position: relative;
  margin: 35px 0 0;
  cursor: pointer;
  img{
    width: 100%;
    max-width: none;
    border-radius: 0;
  } 

  &:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 66px;
    background: url("assets/img/play-btn.svg") no-repeat;
    background-size: 66px;
    cursor: pointer;
  }

  .col-ctn + & {
    margin: 35px -35px 0;
  }
}

