// - - - Terms and conditions modal - - -
.modal {
  display: none;

  &.-active {
    display: block;
  }

  &.-portrait {
    .modal__wrapper {
      width: 65%;
      height: 95vh;

      @include breakpointMax($mobile-xsmall) {
        width: 90%;
      }
    }

    .video-container>iframe {
      height: 85vh;
    }
  }

  .modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.3);
    z-index: 3;
    cursor: pointer;
  }

  .modal__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78vw;
    margin: 0 auto;
    padding: 70px 40px 40px;
    background-color: $white;
    box-shadow: 0 0 9px 3px rgba($black, 0.26);
    border-radius: 5px;
    z-index: 4;
    box-sizing: border-box;
    overflow: auto;
    max-height: 96vh;

    @include breakpointMax($mobile) {
      width: 95%;
      padding: 60px 25px 25px;
    }
  }

  .modal__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      background-image: url("assets/img/close-icon-red.svg");
      background-repeat: no-repeat;
      background-size: 25px;
      transition: background-image ease 200ms;
    }

    &:hover, &:focus {
      &:before {
        background: url("assets/img/close-icon-black.svg");
      }
    }
  }
}
