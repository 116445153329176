.find-your-maxx {

  @include breakpointMax($containerLargeBreakpoint) {
    br{
      display: none;
    }
  }

  .ctn-main > .col-ctn{
    background-color: $color-red;
  }

  .find-your-maxx__left {
      position: relative;
      background-position: top center;
      background-size: cover;

      @include breakpointMax($mobile-medium) {
        min-height: 610px;
      }

      @include breakpointMax($mobile-small) {
        height: 550px;
        min-height: inherit;
        margin-bottom: 10px !important;
      }

      @include breakpointMax($mobile-xsmall) {
        height: 300px;
      }

      /*img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 77% 0%; //to see face in ipad
      }*/

      &::after,
      &::before {
        @include anim_transition(0.5s, 0.4s);
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 1px;
        transform: translate(50px, 0);
        width: 100%;
        height: 100%;
        background-image: url("assets/img/red-border2.png");
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 100%;

        @include breakpointMax($mobile-small) {
          top: auto;
          bottom: -1px;
          left: 0;
          transform: translate(0, 20px);
          background-image: url("assets/img/red-border-bottom2.png");
          background-position: bottom center;
          background-size: 100% auto;
        }
      }

      &::before {
        @include anim_transition(0.5s);
        background-image: url("assets/img/red-border1.png");

        @include breakpointMax($mobile-small) {
          background-image: url("assets/img/red-border-bottom1.png");
        }
      }

      &.animated-block{
        &:after,
        &::before{
          transform: translate(0);
        }
      }
  }

  .find-your-maxx__right {
    position: relative;
    padding: 46px 15px 1px 35px;
    color: $white;

    @include breakpointMax($mobile-small) {
      margin: 0 !important;
      padding: 0 15px 10px;
    }

    h2 {
      margin-bottom: 30px;
      line-height: 30px;
      @include font-size(2);

      @include breakpointMax($mobile-small) {
        margin-bottom: 20px;
        line-height: 40px;
        @include font-size(3.3);
      }

      @include breakpointMax($mobile-xsmall) {
        line-height: 25px;
        @include font-size(2);
      }
    }

    p{
      line-height: 24px;
      @include font-size(1.6);

      @include breakpointMax($mobile-small) {
        line-height: 35px;
        @include font-size(2.5);
      }

      @include breakpointMax($mobile-xsmall) {
        line-height: 20px;
        @include font-size(1.4);
      }
    }

    a {
      @include button($phase2-pink, $white, $color-link-hover, $white);
    }

    .description{
      position: relative;
      left: -25%;
      width: 125%; //100% + negatif left pos
      margin-top: 28px;
      //text-align: justify;
      //font-weight: roman; //TBD
      line-height: 12px;
      @include font-size(1);

      @include breakpointMax($mobile-small) {
        left: 0;
        width: 100%;
        line-height: 20px;
        @include font-size(1.5);
      }
      @include breakpointMax($mobile-xsmall) {
        line-height: 11px;
        @include font-size(0.9);
      }
    }


  }
  .positionned-logo{
    z-index: 2;
    position: absolute;
    top: 40px;
    left: 0;
    transform: translateX(-100%);

    @include breakpointMax($mobile-small) {
      top: -20px;
      left: 20px;
      transform: translateY(-100%);
      width: 45%;
    }
  }

  .button{
    @include breakpointMax($mobile-small) {
      display: table; //align center
      margin: 0 auto 40px;
      padding: 30px 50px;
      line-height: 20px;
      @include font-size(2);
    }

    @include breakpointMax($mobile-xsmall) {
      margin-bottom: 20px;
      padding: 18px 30px;
      line-height: 13px;
      @include font-size(1.3);
    }
  }
}


.find-your-maxx.--step2{

  .find-your-maxx__right {
    width: 33%;
    padding: 46px 65px 1px 55px;

    @include breakpointMax($containerLargeBreakpoint) {
      padding-right: 3%;
      padding-left: 4%;
    }

    @include breakpointMax($mobile-small) {
      width: 100%;
      padding: 0 20px 10px;
    }

    h2 {
      padding-right: 20px;
      line-height: 30px;
      @include font-size(2.4);

      @include breakpointMax($mobile-small) {
        padding-right: 0;
        line-height: 40px;
        @include font-size(3.3);
      }

      @include breakpointMax($mobile-xsmall) {
        line-height: 22px;
        @include font-size(1.8);
      }
    }

    .description{
      left: -90%;
      width: 200%; //100% + negatif left pos

      @include breakpointMax($containerLargeBreakpoint) {
        left: -70%;
        width: 170%;
      }

      @include breakpointMax($mobile-small) {
        left: 0;
        width: 100%;
      }
    }
  }
}

.find-your-maxx.--step3{

  .ctn-main > .col-ctn {
    min-height: 390px;
    background-color: $phase2-darkred;
  }

  .find-your-maxx__left {
    overflow: hidden;

    @include breakpointMax($mobile-small) {
      height: 410px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 1px;
      transform: translate(0) scale(1.1);
      width: 100%;
      height: 100%;
      background-image: url("assets/img/x-mask-min2.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include breakpointMax($mobile-small) {
        left: 0;
        height: calc(100% + 1px);
        background-image: url("assets/img/x-mask-mobile.png");
        background-position: bottom right;
      }
    }

    &::before {
      display: none;
    }

    &.animated-block{
      &:after{
        transform: translate(0) scale(1);
      }
    }
  }

  .find-your-maxx__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-25px);
    padding: 20px 20px 50px;

    @include breakpointMax($mobile-small) {
      transform: none;
    }

    h2 {
      margin-bottom: 0;
      line-height: 36px;
      @include font-size(2.4);

      @include breakpointMax($mobile-small) {
        margin-top: -60px;
        line-height: 40px;
        @include font-size(3.3);
      }

      @include breakpointMax($mobile-small) {
        margin-top: -50px;
      }

      @include breakpointMax($mobile-xsmall) {
        line-height: 22px;
        @include font-size(1.8);
      }
    }

    p{
      margin-bottom: 0;
    }
  }

  .positionned-logo{
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 157px;
  }

  .find-your-maxx__bottom{
    box-shadow: 0 0 4px 0 rgba(#000000, 0.18);
    padding: 46px 64px 26px;

    @include breakpointMax($mobile-small) {
      padding: 30px 20px;
    }

    h4{
      line-height: 36px;
      @include font-size(2);
    }

    .description{
      line-height: 26px;
      @include font-size(1.4);
    }

    .col-ctn{
      @include breakpointMax($container-small){
        justify-content: center;
      }
    }

    .col-1-5{
      width: calc(100% / 5 - 45px);

      @include breakpointMax($container-small){
        width: calc(100% / 5);
        min-width: 150px;
        padding: 0 15px 15px;
      }

      img{
        width: 100%;
        border-radius: 50%;
      }
      a{
        line-height: 16px;
        @include font-size(1.2);
      }
      p{
        margin: 0;
        font-family: $font-accent;
        line-height: 16px;
        @include font-size(1.2);
      }
    }
  }
}
