/* Basic HTML Elements */

* {
	@include box-sizing(border-box)
}


html,
body {
	overflow-x: hidden; /* disables horizontal scrolling on touche devices */
	height: 100%;
}

html {
	font-size: 62.5%;
}

body {
	background-color: $color-bg-body;
	margin: 0;
	font-family: $font-main;
	color: $color-text-main;
	@include font-size($font-size);
	line-height: 26px;
	@include breakpointMax($mobile) {
		overflow-x: hidden;
    @include font-size(1.4);
	  line-height: 20px;
	}
}

h1, h2, h3, h4, h5, h6 {
	clear: both;
	margin: 0;
	font-family: $font-accent;
	font-weight: normal;
}

h1 {

}

h2 {
  @include font-size(4.5);
  line-height: 54px;

  @include breakpointMax($mobile) {
    @include font-size(3);
    line-height: 35px;
  }
}

h3 {
  @include font-size(2.4);
  line-height: 29px;

  @include breakpointMax($mobile) {
    @include font-size(2);
    line-height: 23px;
  }
}

h4 {

}

h5 {

}

p {
	margin-bottom: 1.5em;
}


b, strong {
	font-family: $font-accent;
}

dfn, cite, em, i {
	font-style: italic;
}

sup,
sub {
	@include font-size(1.2);
	height: 0;
	line-height: 0;
	position: relative;
	padding-left: 3px;
}

sup {
	top: -1rem;
}


a {
	color: $color-main;
	text-decoration: none;
	&:visited {
		color: $color-main;
	}
	&:hover,
	&:focus,
	&:active {
		color: $color-main;
		text-decoration: underline;
		outline: 0 none;
	}
}
p a{
	text-decoration: underline;
}

ul, ol {
	margin: 0;
	padding: 0;
	li{
		margin-left: 15px;
	}
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-family: $font-accent;
}

dd {
	margin: 0 1.5em 1.5em;
}

hr {
	background-color: $color-border;
	border: 0;
	width: 100%;
	height: 1px;
	margin: 1.5em 0;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	border: 0 none; /* IE8 */
	.ctn-img & {
		width: 100%;
	}

  @include breakpointMax($mobile) {
    max-width: 100%; /* Adhere to container width. */
  }
}

figure {
	margin: 0;
}

table {
	padding: 0;
	border-spacing: 0;
    border-collapse: separate;
}

::placeholder {
	font-style: italic;
}
