.ctn-main {
	width: $container-width-with-padding;
	max-width: 100%;
  margin-right: auto;
	margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  &.-small{
    width: $container-small;
  }
  &.-large{
    width: $container-large;
  }
  &.-slider-ctn{
    width: $container-slider;
  }
}

.video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    height: 612px;

    @include breakpointMin(426px) {
      height: 100%;
    }
  }
}

.-block{
  display: block;
  clear: both;
}

.-text-centered{
    text-align: center;
}

.-v-align{
  display: flex;
  align-items: center;
}

.-alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}

.-alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}
.-valign{
    display: flex;
    align-items: center;
}

.-centered {
  display: table;
  margin: 0 auto;
}

.-capitalize {
  ::first-letter {
    text-transform: uppercase;
  }
}

.-hide,
.-hidden {
  display: none !important;
}

.-desktop-only {
  display: block;

  @include breakpointMax($mobile-small) {
    display: none;
  }
}

.-mobile-only {
  display: none !important;

  @include breakpointMax($mobile-small) {
    display: block !important;
  }
}

.-colored{
  color: $color-main;
}

.-footnote{
  font-style: italic;
  @include font-size(1.4);
}

/* ==========================================================================
   Button
   ========================================================================== */
.button{
  @include button($color-main, $white, $color-link-hover, $white);
  line-height: 17px;
  @include font-size(1.4);

  @include breakpointMax($mobile) {
    line-height: 14px;
    @include font-size(1.2);
  }
}
