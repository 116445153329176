button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

input[type="search"] {
	.site-search & {
		visibility: hidden;
		float: right;
		width: 1px;
	}
} 

.form-unit {
	width: 100%;
	margin-bottom: 13px;
	@include clearfix;
}

select, 
input,
textarea {
	&.bits-FormControl {
		border-radius: 0;
		height: 38px;
		border: 0 none;
	}
}

textarea {
	&.bits-FormControl {
		height: auto;
	}
}

.bits-FormControl.btn-submit {
	background-color: #d12a2f;
	color: #fff;
}

select[disabled] {
	font-style: italic;
	color: lighten($color-text-main, 50%);
}
select{
	@include selectbox-style;
}


::placeholder {
	font-style: italic;
}

::-webkit-input-placeholder { color: lighten($color-text-main, 50%); }
::-moz-placeholder { color: lighten($color-text-main, 50%); }
:-ms-input-placeholder { color: lighten($color-text-main, 50%); }
input:-moz-placeholder { color: lighten($color-text-main, 50%); }
:focus::-webkit-input-placeholder{ color: transparent; }

#loading {
    /* background: url('../images/loading-big.gif') no-repeat center center; */
    background-size: 100px 100px;
	width: 100px;
	height: 100px;
	margin: 50px 50%;
	display: none;
}

form{
	& > .ctn-main{
		background-color: $white;
		padding: 58px 70px;
	}
	h4{
		margin-bottom: 15px;
	}
}

.form-section{
	background-color: $color-bg-light;
	.button{
		margin-top: 100px;
		margin-bottom: 76px;
		@include breakpointMax($mobile){
			margin: 48px 0;
		}
	}
	.ctn-main{
		@include breakpointMax($mobile){
			margin: 0 10px;
			padding: 30px 20px 0;
			width: calc(100% - 20px);
		}
	}
}
.form-header{
	.text-ctn p{
		margin-bottom: 0;
	}
}

.question-ctn{
	transition: all 2s;
	margin-top: 40px;
	@include breakpointMax($mobile){
		margin-top: 10px; 
	}
	h3{
		margin-bottom: 20px;
		
	}

	&.required-alert {
		background: #EBC3C3;
		box-shadow: #EBC3C3 0 0 0 15px;
	}
}
.form-header + .question-ctn{
	margin-top: 56px;
	@include breakpointMax($mobile){
		margin-top: 10px; 
	}
}

//** validation ** //
.e_alert-mind,
.e_alert-body,
.e_alert-community{
	p{
		color: rgb(197, 85, 85);
	}
}
@include breakpointMax($mobile){
	.form-header + .question-ctn{
		margin-top: 0;
	}
}